<template>
  <v-footer class="footer-compact">
    <v-row justify="center">
      <v-col class="text-right text-footer" cols="6">
        <a target="_blank" href="/data-privacy">Data Privacy</a>
      </v-col>
      <v-col class="text-left text-footer" cols="6">
        <a target="_blank" href="https://www.syslifters.com/en/imprint">Imprint</a>
      </v-col>
    </v-row>
  </v-footer>
</template>

<style lang="scss" scoped>
@use '@/assets/vuetify.scss' as vuetify;

.footer-compact {
  max-height: 2rem;
  height: 2rem !important;
  padding: 0;
  border-top: 1px solid rgba(var(--v-border-color), var(--v-border-opacity));

  &:deep() {
    .v-row {
      margin: 0;
    }
    .v-col {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.text-footer {
  font-size: 0.75rem !important;
}
.text-footer a {
  color: inherit;
  opacity: 0.7;
  font-style: italic;
  text-decoration: none;
}
</style>
